import React from 'react';
import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  Link,
  Spinner,
  VStack,
  Divider,
  Image,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { NoTicketSVG } from './noTicketsSVG';
import { useGuest } from '../api';
import { useStytchUser } from '@stytch/react';
import { LoadingScreen } from '../loader';
import { createTableRequestPublic, rsvpEvent } from '../api';
import { TicketsCarousel } from './ticketCarousel';
import { getTextMessage } from '../components/text-messages';

export function TicketsPage({ event, setTabState }) {
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  console.log(guest);

  const [tableRequestLoading, setTableRequestLoading] = React.useState(false);
  const [tableRequestFinished, setTableRequestFinished] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { slug } = event;

  const textMessage = getTextMessage(slug, event.date, guest?.unique_slug);

  console.log(guest?.events[slug]);

  console.log('Hellow World!');

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box w="100vw" bgColor={'#000000'}>
      <VStack>
        <Text
          w="350px"
          h="18px"
          ml="16px"
          fontWeight="500"
          fontSize={['12px', '24px']}
          lineHeight="18px"
          letterSpacing="0.3px"
          textTransform="uppercase"
          color="rgba(255, 255, 255, 0.92)"
        >
          Your Event Passes
        </Text>
        <Divider />

        {event.event_type === 'paid' ? (
          // Existing logic for paid events
          guest?.events[slug]?.tickets?.length == 0 ||
          !guest?.events[slug]?.tickets?.length ? (
            <Box
              alignContent={'center'}
              justifyContent={'center'}
              onClick={() => {
                setTabState(0);
              }}
            >
              <Box w="100%" alignContent={'center'} justifyContent={'center'}>
                <NoTicketSVG />
              </Box>
            </Box>
          ) : (
            <TicketsCarousel tickets={guest?.events[slug]?.tickets} />
          )
        ) : (
          <Box
            w="100vw"
            h={'104px'}
            alignContent={'center'}
            justifyContent={'center'}
            display={'flex'}
          >
            {!guest?.events[slug]?.requested && (
              <Button
                borderRadius={'71px'}
                background={'linear-gradient(64deg, #9763B4 0%, #8978FB 100%)'}
                boxShadow={'0px 7px 80px -12px rgba(148, 104, 195, 0.20)'}
                isDisabled={guest?.events[slug]?.requested}
                onClick={() => {
                  console.log(slug);
                  rsvpEvent(guest?.auth_service_id, slug).then(() => {
                    window.location.reload();
                  });
                }}
              >
                CLICK TO CONFIRM YOUR ATTENDANCE
              </Button>
            )}
            {guest?.events[slug]?.approved && (
              <Button
                height={'104px'}
                borderRadius={'71px'}
                colorScheme="green"
                boxShadow={'0px 7px 80px -12px rgba(148, 104, 195, 0.20)'}
              >
                <VStack gap="0">
                  <CheckCircleIcon />
                  <Text>ACCESS GRANTED.</Text>
                  <Text>YOU'RE GOING TO RASA x SUNSET MONACO</Text>
                </VStack>
              </Button>
            )}
          </Box>
        )}

        <Divider />

        {/* For the table request card */}

        <Box
          w="100vw"
          alignContent={'center'}
          justifyContent={'center'}
          mb="100px"
          bgColor={'#000000'}
        >
          <VStack>
            <Text
              fontSize="3xl"
              bgGradient="linear(to-l, #FF0080, #7928CA, #FF0080)"
              bgClip="text"
              fontWeight="extrabold"
            >
              SHARE THE FUN
            </Text>

            <a
              href={textMessage}
            >
              <Button
                mt="10px"
                variant="outline"
                borderRadius={'20px'}
                textColor={'white'}
              >
                INVITE FRIENDS
              </Button>
            </a>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}
