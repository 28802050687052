import React from 'react';
import PropTypes from 'prop-types';
import { getTextMessage } from '../../components/text-messages';
import { useParams } from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  VStack,
  IconButton,
  useStyleConfig,
} from '@chakra-ui/react';
import { FaShareAlt } from 'react-icons/fa'; // Import the share icon from react-icons

export default function EventOverview({ flier, title, location, date, guest, startTime, endTime }) {
  const {slug} = useParams()

  const textMessage = getTextMessage(slug, date, guest?.unique_slug);

  return (
    <VStack
      p={3}
      borderRadius="lg"
      className="font-popins"
      justifyContent="center"
      alignItems="center"
    >
      <VStack align="start" spacing={1}>
        <Box position="relative" width={['auto', '840px']}>
          <Image
            alt="Event flier"
            src={flier}
            borderRadius="xl"
            objectFit="cover"
            width="100%"
          />

          <a
            href={textMessage}
          >
            <IconButton
              aria-label="Share"
              icon={<FaShareAlt />} // Use the FaShareAlt icon for the share button
              position="absolute"
              bottom="0" // Position at the bottom of the container
              left="0" // Position at the left of the container
              m={2} // Add some margin to move it slightly from the edge
            />
          </a>
        </Box>
        <Text fontSize={['4xl', '5xl', '6xl']}>{title}</Text>
        <Text textTransform="uppercase" fontSize={['md', 'lg', 'xl']}>
          Location: {location}
        </Text>
        <Text
          textTransform="uppercase"
          color="purple.500"
          fontSize={['sm', 'md', 'lg']}
        >
          Date: {date}
        </Text>
        {location == 'BÂOLI Miami' ? (
          <Text
            fontSize={['sm', 'md', 'lg']}
            fontWeight="normal"
            color="purple.500"
          >
            10:30 PM - 3:30 AM
          </Text>
        ) : (
          <Text
            fontSize={['sm', 'md', 'lg']}
            fontWeight="normal"
            color="purple.500"
          >
            {startTime} - {endTime}
          </Text>
        )}
      </VStack>
    </VStack>
  );
}

EventOverview.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  flier: PropTypes.string,
};