import PropTypes from 'prop-types';
import { Box, Text, VStack, HStack, Icon } from '@chakra-ui/react';

import AnnoucmentIcon from './AnnoucementIcon';
import InfoIcon from './InfoIcon';
import DescriptionComponent from './AboutComponent';

export default function EventDetails({ description, presentedBy }) {
  return (
    <VStack
      className="font-poppins"
      bg="dark.500"
      p={4}
      rounded="md"
      shadow="md"
      color="gray.400"
      alignItems="center"
    >
      <Box>
        <DescriptionComponent description={description} wordLimit={300} />

        <VStack spacing={2} mt={4} align="stretch">
          <HStack spacing={2}>
            <Icon as={AnnoucmentIcon} />
            <Text fontSize="sm">{presentedBy}</Text>
          </HStack>
          <HStack spacing={2}>
            <Icon as={InfoIcon} />
            <Text fontSize="sm">This event is 18+</Text>
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
}

EventDetails.propTypes = {
  description: PropTypes.string.isRequired,
  presentedBy: PropTypes.string,
  refundPolicy: PropTypes.string,
  agePolicy: PropTypes.string,
};
