import { Text, Button, Box, VStack, HStack } from '@chakra-ui/react';

import { useStytchUser, useStytch } from '@stytch/react';
import { useGuest } from '../../api';

export default function ConfirmSignUp() {
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  return (
    <VStack justifyContent="space-between" alignItems="start" h="95vh">
      <VStack marginLeft="2rem" alignItems="start">
        <Text
          marginTop={'6vh'}
          fontSize="3xl"
          textAlign="start"
          lineHeight="1.2"
        >
          YOU'RE
          <br />
          OFFICIALLY ON
          <br />
          THE TICKET PRESALE! 🎉
        </Text>
        <Text fontSize="md" color="#8F8BA3" textAlign="start">
          You will receive a text message with a dedicated link to
          <br />
          checkout and complete your purchase.
           
        </Text>
        
        <a
          href={`sms://?&body=Hey%2C%20I%20am%20going%20to%20NEITHERWORLD%20By%20RASA%20on%20November%201st%202024.%20Use%20this%20link%20to%20get%20access%20%3A%20https://rasa.fyi/${guest?.unique_slug}`}
        >
          <Button
            color="white"
            background="linear-gradient(180deg, #130213 0%, #0A010A 100%)"
            border="1px #3F3C55 solid"
            borderRadius="40px"
            width="63vw"
            height="50px"
            fontSize="1rem"
            fontWeight="400"
            onClick={() => {
              window.location.href = '/event';
            }}
          >
            INVITE FRIENDS TO APPLY
          </Button>
        </a>
      </VStack>
      <Box marginBottom="2rem" marginLeft="2rem">
        <Text
          fontSize="md"
          color="#6F87A0EB"
          textAlign={'center'}
          marginBottom=".5rem"
          lineHeight="1.2rem"
        >
          For any questions, please reach out to team@rasa.world
        </Text>
        <HStack justifyContent="center">
          <Text fontSize="xs" color="#6F87A0EB" opacity="0.9">
            Already have an account?
          </Text>
          <Text
            fontSize="xs"
            color="#6F87A099"
            onClick={() => {
              window.location.href = '/login';
            }}
          >
            Sign In
          </Text>
        </HStack>
      </Box>
    </VStack>
  );
}
