import React from 'react';
import { Global, css } from '@emotion/react';
import { HStack, keyframes } from '@chakra-ui/react';

import {
  Text,
  Button,
  Box,
  VStack,
  Input,
  AvatarGroup,
  InputLeftAddon,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { RasaLogo } from './logo';

import { useStytchUser, useStytch } from '@stytch/react';
import { useGuest } from '../api';

export function ConfirmSignUp() {
  const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  return (
    <>
      <Box
        h="120vh"
        w="100vw"
        alignContent="center"
        justifyContent="center"
        bgSize="cover"
        bgPosition="center"
        display="flex"
        bgImage={
          'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
        }
        bgRepeat="no-repeat"
      >
        <VStack>
          <Box marginTop="5vh">
            <RasaLogo />
          </Box>

          <Box
            marginTop="5vh"
            h="80vh"
            w="90vw"
            bgColor="#0C0A29"
            borderRadius="18px"
            borderColor="gray.700"
          >
            <VStack>
              <Text
                textAlign={'center'}
                marginTop="5vh"
                fontWeight="500"
                fontSize="31.25px"
                bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                bgClip="text"
              >
                You are officially on
                <br /> the waitlist!
              </Text>

              <Text
                marginLeft={'10vw'}
                marginRight={'10vw'}
                fontSize="md"
                color="#6F87A0EB"
                opacity="0.9"
                textAlign={'center'}
              >
                If approved, you will receive a text with a link to complete
                your rsvp.
              </Text>

              <a
                w={'100vw'}
                href={`sms://?&body=Hey%2C%20I%20am%20going%20to%20NEITHERWORLD%20By%20RASA%20on%20November%201st%202024.%20Use%20this%20link%20to%20get%20access%20%3A%20https://rasa.fyi/${guest?.unique_slug}`}
              >
                <Button
                  borderRadius={'71px'}
                  marginTop="1vh"
                  marginBottom={'7vh'}
                  borderColor={'gray.700'}
                  borderWidth={'1px'}
                  opacity={'0.92'}
                  height={'52px'}
                  width={'70vw'}
                  bgColor={'#9763B4'}
                  fontSize={'sm'}
                  onClick={() => {
                    window.location.href = '/event';
                  }}
                >
                  Invite Friends To Apply
                </Button>
              </a>

              <Text
                fontSize="md"
                color="#6F87A0EB"
                opacity="0.9"
                textAlign={'center'}
              >
                For any questions, please reach out to team@rasa.world
              </Text>
              <HStack>
                <Text fontSize="xs" color="#6F87A0EB" opacity="0.9">
                  Have you been approved?
                </Text>
                <Text
                  fontSize="xs"
                  color="#6F87A099"
                  onClick={() => {
                    window.location.href = '/login';
                  }}
                >
                  Sign In
                </Text>
              </HStack>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
