import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Heading,
  Text,
  Image,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DoorsOpenIcon from './DoorsOpenIcon';

export default function VenueDetails({
  venueName,
  venueAddress,
  venuePicture,
  doorsOpen,
  startTime,
}) {
  const handleMapRedirect = () => {
    const mapsLink = `https://www.google.com/maps?q=${encodeURIComponent(
      venueAddress
    )}`;
    window.open(mapsLink, '_blank');
  };

  return (
    <Box p={5} borderRadius="md" boxShadow="sm" mb={'10'}>
      <Flex direction={{ base: 'row', md: 'column' }} align="center">
        <VStack align="start" flex="1">
          <Heading size="md" mb={1}>
            Venue
          </Heading>
          <Text fontSize="lg">{venueName}</Text>
          <Text fontSize="sm" color="gray.400" mb={4}>
            {venueAddress}
          </Text>
          <Button
            leftIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
            borderRadius="full"
            onClick={handleMapRedirect}
          >
            OPEN IN MAPS
          </Button>
        </VStack>
        <Box flex="1" alignSelf="end" mt={5}>
          <Image
            alt="Album cover"
            borderRadius="md"
            boxShadow="md"
            src={
              'https://drupal-prod.visitcalifornia.com/sites/default/files/styles/fluid_1920/public/2021-03/VC-Santa-Monica-Pier-CR-Advanced-Rights-1280x640.jpg.webp?itok=hTfZ7_7S'
            }
            objectFit="cover"
          />
        </Box>
      </Flex>

      <VStack align="start" mt={4} gap={2}>
        <Flex align="center" gap={2}>
          <DoorsOpenIcon />
          <Text fontSize="sm" color="gray.400">
            Doors Open
          </Text>
          <Text fontSize="sm">{startTime}</Text>
        </Flex>
      </VStack>
    </Box>
  );
}

VenueDetails.propTypes = {
  venueName: PropTypes.string.isRequired,
  venueAddress: PropTypes.string.isRequired,
  venuePicture: PropTypes.string,
  doorsOpen: PropTypes.string,
};
