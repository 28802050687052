import React, { useState } from 'react';

import { Text, Button, VStack, Input, Box, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useStytchUser, useStytch } from '@stytch/react';

import { NEXT_PUBLIC_DEPLOY_URL } from '../../constants';

export default function SignUpPageOne({ incrementStep }) {
  const stytchUser = useStytchUser();

  const { slug } = useParams();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    igHandle: '',
    location: '',
    igHandle: '',
    unique_invite_code: slug,
    phone: stytchUser?.user?.phone_numbers[0]?.phone_number,
  });

  const event = (slug === 'neitherworld') ? "NEITHERWORLD" : "PIER PLAY"

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    // Set loading state
    // Also prevents multiple submissions
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      // Mock API call
      const response = await fetch(
        `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/${stytchUser?.user?.user_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        if (data.approvalStatus === 'approved') {
          if (slug === 'neitherworld'){
          window.location.href = '/spEvent/neitherworld';
          }
          else{
            window.location.href = '/spEvent/pierplay';
          }
        } else {
          window.location.href = '/confirmSignup';
        }
        toast({
          title: 'Success!',
          description: 'You have been registered.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Handle successful response
      } else {
        setLoading(false);
        toast({
          title: 'Error!',
          description: 'Something went wrong.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // Handle error response
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error!',
        description: 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Handle network error
    }
  };
  return (
    <VStack justifyContent="space-between" h="95vh">
      <VStack marginLeft="2rem" alignItems="start">
        <Text
          marginTop={'6vh'}
          fontSize="3xl"
          textAlign="start"
          lineHeight="1.2"
        >
          WE WANT TO
          <br /> KNOW YOU BETTER
        </Text>
        <Text fontSize="sm" color="#8F8BA3" textAlign="start">
          Please enter your first name, last name, email,
          <br />
          ig handle, and city of residence.
        </Text>
        <Box marginTop="2rem">
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="First Name"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            mb="1.5rem"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={formData.firstName}
            isInvalid={errors.firstName}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="Last Name"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="lastName"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.lastName}
            isInvalid={errors.lastName}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="Email"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="email"
            name="email"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.email}
            isInvalid={errors.email}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="IG Handle"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="igHandle"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.igHandle}
            isInvalid={errors.igHandle}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="City"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="location"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.location}
            isInvalid={errors.location}
          />
        </Box>
      </VStack>

      <Button
        marginTop={'10px'}
        marginBottom={'20px'}
        border={'1px solid gray.700'}
        borderRadius={'40px'}
        h={'60px'}
        alignSelf="center"
        color={'#0C0A18'}
        background={'#DFDFF2'}
        boxShadow={'4px 3px 20px rgba(85, 102, 255, 0.30)'}
        fontSize="1.2rem"
        onClick={handleSubmit}
        isDisabled={
          !formData.firstName ||
          !formData.lastName ||
          !formData.email ||
          !formData.igHandle ||
          !formData.location
        }
        isLoading={loading}
      >
        <VStack>
          <Text>SIGNUP FOR {event} BY RASA</Text>
        </VStack>
      </Button>
    </VStack>
  );
}
