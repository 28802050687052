import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputGroup, InputLeftAddon } from '@chakra-ui/react';

interface ChakraPhoneProps {
  value: string;
  onChange: (data: {
    phone: string;
    country: { iso2: string };
    inputValue: string;
  }) => void;
}

export const ChakraPhone: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (phone: string, country: any, e: any, formattedValue: string) => {
    // Remove any non-digit characters
    let digitsOnly = phone.replace(/\D/g, '');

    // Remove the dial code from the beginning of digitsOnly
    let nationalNumber = digitsOnly.startsWith(country.dialCode) 
      ? digitsOnly.slice(country.dialCode.length) 
      : digitsOnly;

    // Remove leading zero if present
    nationalNumber = nationalNumber.startsWith('0') ? nationalNumber.slice(1) : nationalNumber;

    // Construct the full phone number
    let processedPhone = nationalNumber ? `+${country.dialCode}${nationalNumber}` : '';

    onChange({
      phone: processedPhone,
      country: { iso2: country.countryCode },
      inputValue: formattedValue
    });
  };

  return (
    <InputGroup width="50%">
      <PhoneInput
        country={'us'}
        value={value}
        onChange={handleChange}
        copyNumbersOnly={true}
        jumpCursorToEnd={true}
        localization={true}
        countryCodeEditable={false}
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true,
          autoComplete: 'tel',
        }}
        containerStyle={{
          width: '100%',
        }}
        inputStyle={{
          width: '100%',
          height: '3rem',
          background: '#0A010A',
          color: 'white',
          border: '1px solid #282536',
          borderRadius: '1rem',
        }}
        buttonStyle={{
          background: '#0A010A',
          border: '1px solid #282536',
          borderRadius: '1rem 0 0 1rem',
          height: '3rem',
        }}
        dropdownStyle={{
          background: '#0A010A',
          color: 'white',
        }}
        enableSearch={true}
        disableSearchIcon={true}
      />
    </InputGroup>
  );
};