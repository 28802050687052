import React from 'react';

import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useEventBySlug, useFetchPromoterByID, useGuest } from '../api';
import { LoadingScreen } from '../loader';

import EventDetailsPublic from './components/EventDetail';
import Checkout from './components/Checkout';
import Purchase from './components/Purchase';

import { useStytchUser, useStytchSession } from '@stytch/react';
export default function PublicEventCheckoutPage() {
  const slug = "neitherworld"
  const params = new URLSearchParams(window.location.search);
  const promoterID = params.get('special');

  const stytchUser = useStytchUser();
  const stytchSess = useStytchSession();

  // Fetch event and promoter data
  const { data: event, isLoading: eventLoading } = useEventBySlug(slug);
  const { data: promoter, isLoading: promoterLoading } =
    useFetchPromoterByID(promoterID);

  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  // Local state to manage page content
  const [pageState, setPageState] = React.useState('detail');

  const [ticketsSelected, setTicketsSelected] = React.useState({});

  // Show loading screen if data is being loaded
  if (eventLoading || promoterLoading) {
    return <LoadingScreen />;
  }

  // Render content based on the page state
  return (
    <Box>
      <AnimatePresence>
        {pageState === 'detail' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.0 }}
          >
            <EventDetailsPublic
              event={event}
              promoter={promoter}
              setPageState={setPageState}
              guest={guest}
            />
          </motion.div>
        )}
        {pageState === 'checkout' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Checkout
              event={event}
              promoter={promoter}
              setPageState={setPageState}
              ticketsSelected={ticketsSelected}
              setTicketsSelected={setTicketsSelected}
              guest={guest}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}
