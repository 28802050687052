import React from 'react';
import {
  Box,
  Button,
  Text,
  HStack,
  VStack,
  Spinner,
  Divider,
  Image,
  Toast,
  Icon,
} from '@chakra-ui/react';

import { AnimatePresence, motion } from 'framer-motion';
import Checkout from '../public-checkout/components/Checkout';
import { getTextMessage } from '../components/text-messages';
import {
  CheckCircleIcon,
  CalendarIcon,
  ArrowRightIcon,
} from '@chakra-ui/icons';
import { AddToCalendar } from './addToCalendar';
import ICalendarLink from 'react-icalendar-link';
import { useGuest, createTableRequest } from '../api';
import { useStytchUser } from '@stytch/react';
import { useStytchSession } from '@stytch/react';
import { LoadingScreen } from '../loader';
import { useMutation } from 'react-query';
import {
  createTableRequestPublic,
  useFetchPromoterByID,
  rsvpEvent,
  fetchAppleWalletPass,
} from '../api';
import { useDisclosure } from '@chakra-ui/react';
import { FaCalendar } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';

import EventOverview from './components/EventCard';
import EventDetails from './components/EventAboutDescription';
import VenueDetails from './components/VenueDetails';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { AuthFlowType } from '@stytch/vanilla-js';

function formatDateWithDayOfWeek(inputDate) {
  // Split the input string into parts
  const parts = inputDate.split('/');

  // Extract month, day, and year
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Create a Date object to get the day of the week and month's name
  const dateObj = new Date(year, month - 1, day);

  // Array of day names and month names
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get the day of the week and month's name
  const dayOfWeek = dayNames[dateObj.getDay()];
  const monthName = monthNames[dateObj.getMonth()];

  // Add the appropriate suffix for the day
  let daySuffix;
  if (day >= 11 && day <= 13) {
    daySuffix = 'th';
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = 'st';
        break;
      case 2:
        daySuffix = 'nd';
        break;
      case 3:
        daySuffix = 'rd';
        break;
      default:
        daySuffix = 'th';
        break;
    }
  }

  // Construct the formatted date string
  const formattedDate = `${dayOfWeek}, ${monthName} ${day}${daySuffix}`;

  return formattedDate;
}

// Example usage
const inputDate = '03/24/2023';
const formattedDate = formatDateWithDayOfWeek(inputDate);
console.log(formattedDate); // Output: "Sunday, March 24th"

export function EventPage({ event, setTabState }) {
  const stytchUser = useStytchUser();
  const stytchSess = useStytchSession();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  const { slug } = event;

  const textMessage = getTextMessage(slug, event.date, guest?.unique_slug);
  const toast = useToast();

  const params = new URLSearchParams(window.location.search);
  const promoterID = params.get('special');
  const { data: promoter, isLoading: promoterLoading } =
    useFetchPromoterByID(promoterID);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenTable,
    onOpen: onOpenTable,
    onClose: onCloseTable,
  } = useDisclosure();

  const createFetchAppleWalletPass = useMutation(
    () => fetchAppleWalletPass(guest._id),
    {
      onSuccess: data => {
        console.log(data);
        setTimeout(() => {
          window.open(data, '_blank');
        }, 1000);
      },
    }
  );

  console.log(event);

  const changeTab = tab => {
    setTabState(tab);
  };

  const [tableRequestLoading, setTableRequestLoading] = React.useState(false);
  const [tableRequestFinished, setTableRequestFinished] = React.useState(false);

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const [isCopied, setIsCopied] = React.useState(false);

  const [pageState, setPageState] = React.useState('checkout');
  const [ticketsSelected, setTicketsSelected] = React.useState({});

  console.log(guest);

  React.useEffect(() => {
    // if the session is expired, log the user out
    console.log(stytchSess);
    if (stytchSess?.session == null) {
      window.location.href = '/login?accessToken=la-exclusive';
    }

    if (guest?.events[slug]?.ownGuestList == true) {
      onOpen2();
    }
  }, [stytchSess, guest]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      bgColor={'#000000'}
      display={'flex'}
      width={'100%'}
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="flex-start"
    >
      <Box flex="1" p={{ base: 4, md: 6 }} maxW={{ base: "100%", md: "60%"}} >
      <EventOverview
        flier={event.eventImageURL}
        title={event.eventDetails.eventName}
        location={event.eventDetails.eventLocation}
        date={event.eventDetails.eventStartDate}
        guest={guest}
        startTime={event.eventDetails.eventStartTime}
        endTime={event.eventDetails.eventEndTime}
      />
      </Box>

      <Box flex="2" p={{ base: 4, md: 6 }} maxW={{ base: "100%", md: "40%" }}>
        <EventDetails
          description={event.eventDetails.eventAboutDescription}
          presentedBy={'Presented by RASA'}
        />
        <VenueDetails
          venueName={event.eventDetails.eventLocation}
          venueAddress={event.eventDetails.eventAddress}
          venueCity={event.eventDetails.eventCity}
          venueState={event.eventDetails.eventState}
          venueZip={event.eventDetails.eventZip}
          venueCountry={event.eventDetails.eventCountry}
          venueDescription={event.eventDetails.eventLocationDescription}
          startTime={event.eventDetails.eventStartTime}
        />

        <Flex
          mt="50px"
          justifyContent="center" // center horizontally
          alignItems="center" // center vertically
        >
          <Flex
            direction="column"
            alignItems="center"
            w="350px"
            bg="#130213"
            border="0.5px solid #2E092A"
            boxShadow="0px 0px 10px rgba(85, 102, 255, 0.16)"
            borderRadius="16px"
            borderWidth={'1px'}
            gap="16px"
            p="24px 24px 24px 24px"
            mt="50px" 
            justifyContent="center"
          >
            <Text
              fontWeight="500"
              fontSize="20px"
              lineHeight="18px"
              letterSpacing="0.3px"
              color="rgba(255, 255, 255, 0.92)"
            >
              Request A Table
            </Text>
            <Text
              w="318px"
              h="38px"
              fontWeight="500"
              fontSize="12px"
              lineHeight="18px"
              letterSpacing="0.3px"
              color="#9795B5"
              mb="16px"
              textAlign={'center'}
            >
              Submit a request and we will reach out soon!
              <br />
              Or contact us on team@rasa.world
            </Text>
            <Button
              w="119px"
              h="52px"
              boxShadow="0px 7px 80px -12px #5566FF"
              borderRadius="840px"
              color="#FFFFFF"
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.15px"
              borderColor={'white'}
              borderWidth={'1px'}
              onClick={onOpenTable}
            >
              REQUEST
            </Button>
            <Modal isOpen={isOpenTable} onClose={onCloseTable}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>REQUEST TABLE</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {tableRequestLoading ? (
                    <Flex direction="column" alignItems="center">
                      <Spinner mb="2" />
                      <Text>Processing your request...</Text>
                    </Flex>
                  ) : tableRequestFinished ? (
                    <Text>
                      Your table request has been confirmed, we will reach out
                      soon! 🎉
                    </Text>
                  ) : (
                    <Text>
                      Are you sure you want to request a table for this event?
                    </Text>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    isDisabled={tableRequestLoading || tableRequestFinished}
                    onClick={() => {
                      setTableRequestLoading(true);
                      createTableRequest(guest._id, slug);
                      // Wait 2 seconds to simulate a request
                      setTimeout(() => {
                        setTableRequestLoading(false);
                        setTableRequestFinished(true);
                      }, 2000);
                    }}
                  >
                    CONFIRM
                  </Button>
                  <Button variant="ghost" onClick={onCloseTable}>
                    {tableRequestLoading || tableRequestFinished
                      ? 'CLOSE'
                      : 'CANCEL'}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>

        <Box
          mt="20px"
          w="100vw"
          background="linear-gradient(180deg, rgba(8, 4, 25, 0) 0%, rgba(85, 102, 255, 0.12) 48.67%, rgba(8, 4, 25, 0) 100%)"
          alignContent={'center'}
          justifyContent={'center'}
          display={'flex'}
          order={1}
          flexGrow={0}
        >
          <VStack>
            <Text
              fontSize="3xl"
              bgGradient="linear(to-l, #FF0080, #7928CA, #FF0080)"
              bgClip="text"
              fontWeight="extrabold"
            >
              SHARE THE FUN
            </Text>

            <a href={textMessage}>
              <Button mt="10px" variant="outline" borderRadius={'20px'}>
                INVITE FRIENDS
              </Button>
            </a>
          </VStack>
        </Box>

        <Box
          mb="5vh"
          h="500px"
          alignContent={'center'}
          justifyContent={'center'}
          display={'flex'}
        >
          <VStack>
            <Divider orientation="horizontal" />

            <a href="https://www.instagram.com/p/C_qdR3ZPiR5/">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="56"
                  height="56"
                  rx="16.8"
                  fill="#09121D"
                  fill-opacity="0.9"
                />
                <path
                  d="M23.3313 16.7998C19.7296 16.7998 16.7998 19.7323 16.7998 23.335V32.6683C16.7998 36.27 19.7323 39.1998 23.335 39.1998H32.6683C36.27 39.1998 39.1998 36.2673 39.1998 32.6646V23.3313C39.1998 19.7296 36.2673 16.7998 32.6646 16.7998H23.3313ZM34.5331 20.5331C35.0483 20.5331 35.4665 20.9513 35.4665 21.4665C35.4665 21.9817 35.0483 22.3998 34.5331 22.3998C34.0179 22.3998 33.5998 21.9817 33.5998 21.4665C33.5998 20.9513 34.0179 20.5331 34.5331 20.5331ZM27.9998 22.3998C31.0882 22.3998 33.5998 24.9114 33.5998 27.9998C33.5998 31.0882 31.0882 33.5998 27.9998 33.5998C24.9114 33.5998 22.3998 31.0882 22.3998 27.9998C22.3998 24.9114 24.9114 22.3998 27.9998 22.3998ZM27.9998 24.2665C27.0097 24.2665 26.0601 24.6598 25.3599 25.3599C24.6598 26.0601 24.2665 27.0097 24.2665 27.9998C24.2665 28.9899 24.6598 29.9395 25.3599 30.6397C26.0601 31.3398 27.0097 31.7331 27.9998 31.7331C28.9899 31.7331 29.9395 31.3398 30.6397 30.6397C31.3398 29.9395 31.7331 28.9899 31.7331 27.9998C31.7331 27.0097 31.3398 26.0601 30.6397 25.3599C29.9395 24.6598 28.9899 24.2665 27.9998 24.2665Z"
                  fill="#6F87A0"
                />
              </svg>
            </a>

            <Text
              fontSize="3xl"
              color="#FFFFFF"
              fontWeight="500"
              bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
              bgClip="text"
              textAlign="center"
              letterSpacing={'0.0em'}
            >
              Follow us on
              <br />
              Instagram
            </Text>
            <Text fontSize="md" color="#6F87A0EB" opacity="0.9">
              @rasa.us
            </Text>

            <Box
              bgImage="url('/images/instaBackground.png')"
              bgSize="cover"
              w="500px"
              h="500px"
              borderRadius="16px"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            />
          </VStack>
        </Box>
      </Box>

      <Box
        position="fixed"
        width="100%"
        bottom="0"
        h="100px"
        display="flex"
        justifyContent="center"
        padding="1rem"
        borderRadius={'16px'}
      >
        <HStack>
          <Button
            bgGradient={'linear(to-r, #9763B4, #8978FB)'}
            h={'50px'}
            size={'md'}
            borderRadius={'20px'}
            onClick={() => {
              if (event.event_type === 'free') {
                rsvpEvent(guest?.auth_service_id, slug).then(() => {
                  window.location.reload();
                });
              }

              if (event.event_type === 'paid') {
                if (guest.events[slug]?.tickets.length > 0) {
                  setTabState(1);
                } else {
                  onOpen();
                }
              }
            }}
          >
            {event.event_type === 'paid' ? (
              // Logic for paid events
              guest.events[slug]?.tickets.length > 0 ? (
                <HStack>
                  <CheckCircleIcon />
                  <Text>VIEW YOUR PASSES</Text>
                </HStack>
              ) : (
                <Text>GET PASSESS</Text>
              )
            ) : // Logic for non-paid events
            guest.events[slug]?.requested === true ? (
              <HStack gap="0">
                <VStack textColor={'white'}>
                  <Text>ACCESS REQUESTED.</Text>
                  <Text>MORE INFORMATION TO COME!</Text>
                </VStack>
              </HStack>
            ) : (
              <HStack>
                <Text>REQUEST ACCESS</Text>
              </HStack>
            )}
          </Button>
          {event.event_type === 'paid' &&
            guest.events[slug]?.tickets.length > 0 && (
              <Button
                bgGradient={'linear(to-r, #9763B4, #8978FB)'}
                h={'50px'}
                size={'md'}
                borderRadius={'20px'}
                onClick={() => {
                  onOpen();
                }}
              >
                <Text>GET PASSESS</Text>
              </Button>
            )}
        </HStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bgColor={'black'}>
            <ModalCloseButton />
            <ModalBody>
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* Your existing component or content goes here */}
                  <Checkout
                    event={event}
                    promoter={promoter}
                    setPageState={setPageState}
                    ticketsSelected={ticketsSelected}
                    setTicketsSelected={setTicketsSelected}
                    guest={guest}
                  />
                </motion.div>
              </AnimatePresence>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}
