import React, { useState, useEffect } from 'react';
import ICalendarLink from 'react-icalendar-link';
import {
  Input,
  Text,
  Box,
  HStack,
  VStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useStytch, useStytchUser } from '@stytch/react';
import { useFetchTicketsByPaymentID, useGuest } from '../api'; // Assuming these hooks are correctly implemented
import { LoadingScreen } from '../../src/loader';
import { Logo } from '../Logo';
import { updateGuestAfterSpringThrill } from '../api';
import { NEXT_PUBLIC_DEPLOY_URL } from '../constants';
import Confetti from 'react-confetti';

export default function CheckoutConfirmation() {
  const stytchClient = useStytch(); // Assuming this is correctly implemented and used elsewhere
  const params = new URLSearchParams(window.location.search);
  const paymentIntentID = params.get('payment_intent');
  const user = useStytchUser();
  const { guest, loading: guestLoading } = useGuest(user?.user?.user_id);
  const { data: tickets, loading: ticketsLoading } =
    useFetchTicketsByPaymentID(paymentIntentID);

  const toast = useToast();

  const [showConfetti, setShowConfetti] = useState(true);
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    igHandle: '',
    zipCode: '',
    igHandle: '',
  });

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 10000); // Show confetti for 5 seconds
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    console.log(tickets); // Log tickets data for debugging, consider removing for production
  }, [tickets]);

  const handleChange = e => {
    console.log(formData);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    // Set loading state
    // Also prevents multiple submissions
    setSubmit(true);

    if (!validateForm()) {
      setSubmit(false);
      return;
    }

    try {
      // Mock API call
      const response = await fetch(
        `${NEXT_PUBLIC_DEPLOY_URL}/api/update_guest/${user?.user?.user_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setSubmit(false);

        toast({
          title: 'Success!',
          description: 'You have been registered.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Reload the page to show the confirmation view
        window.location.reload();
        // Handle successful response
      } else {
        setSubmit(false);
        toast({
          title: 'Error!',
          description: 'Something went wrong.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // Handle error response
      }
    } catch (error) {
      setSubmit(false);
      toast({
        title: 'Error!',
        description: 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      setSubmit(false);
    }
  };

  if (guestLoading || ticketsLoading) {
    return <LoadingScreen />;
  }

  if (guest.email === 'empty@default.com') {
    // User needs to fill in their details
    return (
      <VStack justifyContent="space-between" h="95vh">
        
        <VStack marginLeft="2rem" alignItems="start">
          <Text
            marginTop={'6vh'}
            fontSize="3xl"
            textAlign="start"
            lineHeight="1.2"
          >
            WE WANT TO
            <br /> KNOW YOU BETTER
          </Text>
          <Text fontSize="sm" color="#8F8BA3" textAlign="start">
            Please enter your first name, last name, email,
            <br />
            IG handle, and zip code.
          </Text>
          <Box marginTop="2rem">
            <Input
              borderRadius="15px"
              background="#0A010A"
              color="white"
              border="1px solid #282536"
              textAlign="left"
              placeholder="First Name"
              size="lg"
              focusBorderColor="#5555FF"
              width="82vw"
              mb="1.5rem"
              type="text"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              isInvalid={errors.firstName}
            />
            <Input
              borderRadius="15px"
              background="#0A010A"
              color="white"
              border="1px solid #282536"
              textAlign="left"
              placeholder="Last Name"
              size="lg"
              focusBorderColor="#5555FF"
              width="82vw"
              type="text"
              name="lastName"
              mb="1.5rem"
              onChange={handleChange}
              value={formData.lastName}
              isInvalid={errors.lastName}
            />
            <Input
              borderRadius="15px"
              background="#0A010A"
              color="white"
              border="1px solid #282536"
              textAlign="left"
              placeholder="Email"
              size="lg"
              focusBorderColor="#5555FF"
              width="82vw"
              type="email"
              name="email"
              mb="1.5rem"
              onChange={handleChange}
              value={formData.email}
              isInvalid={errors.email}
            />
            <Input
              borderRadius="15px"
              background="#0A010A"
              color="white"
              border="1px solid #282536"
              textAlign="left"
              placeholder="IG Handle"
              size="lg"
              focusBorderColor="#5555FF"
              width="82vw"
              type="text"
              name="igHandle"
              mb="1.5rem"
              onChange={handleChange}
              value={formData.igHandle}
              isInvalid={errors.igHandle}
            />
            <Input
              borderRadius="15px"
              background="#0A010A"
              color="white"
              border="1px solid #282536"
              textAlign="left"
              placeholder="Zip Code"
              size="lg"
              focusBorderColor="#5555FF"
              width="82vw"
              type="text"
              name="zipCode"
              mb="1.5rem"
              onChange={handleChange}
              value={formData.zipCode}
              isInvalid={errors.zipCode}
            />
          </Box>
        </VStack>

        <Button
          marginTop="10px"
          marginBottom="20px"
          borderRadius="40px"
          width="85vw"
          height="50px"
          alignSelf="center"
          fontSize="1.2rem"
          isLoading={submit}
          onClick={handleSubmit}
          isDisabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.email ||
            !formData.igHandle ||
            !formData.zipCode
          }
        >
          GET YOUR TICKETS!
        </Button>
      </VStack>
    );
  }

  // User confirmation view
  return (
    <Box
      bgImage={'/images/confirmation.png'}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      h="100vh"
      w="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={'column'}
    >
      {showConfetti && <Confetti />}
      <Logo />

      <Box
        mt="100px"
        alignSelf="stretch"
        sx={{
          background:
            'var(--Gradient-Gray, linear-gradient(108deg, #FFF 0%, rgba(189, 215, 241, 0.50) 100%))',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        <Text
          sx={{
            textAlign: 'center',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontSize: '39px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '48.75px', // Alternatively, you can use a unitless value for line-height which is relative to the font size
            textTransform: 'uppercase',
          }}
        >
          YOU'RE GOING!
        </Text>
        <Text
          sx={{
            textAlign: 'center',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '500',
          }}
        >
          Your receipt has been sent to your email. Please check your spam if
          you don't see it in your main inbox.
        </Text>
      </Box>
      <VStack>
        <Text
          color="#EEEDF2" // Use a fallback color if the CSS variable isn't defined
          textAlign="center"
          fontSize="20px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="150%" // This sets the line-height to 150% of the font size, equivalent to 30px for a 20px font size
          sx={{
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
          mt="20px"
        >
          Neitherworld By RASA
        </Text>
        <Text
          color="#EEEDF2" // Use a fallback color if the CSS variable isn't defined
          textAlign="center"
          fontSize="20px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="150%" // This sets the line-height to 150% of the font size, equivalent to 30px for a 20px font size
          sx={{
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
        >
          Friday, July 19th, 2024
        </Text>
        <HStack mt="20px">
          <ICalendarLink
            event={{
              title: 'Neitherwrold By RASA',
              description:
                'Step into RASA’S riveting take of Tim Burton’s Neitherworld this Halloween in London with our partners at Boneca. A transformed venue experience like no other Halloween. Take part in the whimsical and gothic imagination of Tim Burton, with the hauntingly edge of house tunes and spine-tingling drops that will keep the dance floor alive. The other side is waiting for you, feeling spooky yet?',
              startTime: '11/01/2024' + ' ' + '10:00 AM',
              endTime: '11/02/2024' + ' ' + '4:00 AM',
              location: 'Ariel Way, London W12 7SL Kingdom',
            }}
          >
            <HStack>
              <Text>Add to Calendar</Text>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.1667 10.25H5.83337M8.33337 4V5.66667M16.6667 4V5.66667M7.50004 19H17.5C18.4205 19 19.1667 18.2538 19.1667 17.3333V7.33333C19.1667 6.41286 18.4205 5.66667 17.5 5.66667H7.50004C6.57957 5.66667 5.83337 6.41286 5.83337 7.33333V17.3333C5.83337 18.2538 6.57957 19 7.50004 19Z"
                  stroke="#9494FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </HStack>
          </ICalendarLink>
        </HStack>
        <a
          href={`sms://?&body=Hey%2C%20I%20am%20going%20to%20GROOVE%20By%20RASA%20on%20July%2019th%202024.%20Use%20this%20link%20to%20get%20access%20%3A%20https://rasa.fyi/${guest?.unique_slug}`}
        >
          <Button w="100%" h="52px" borderRadius="71px" bg="#9763B4" mt="50px">
            <Text
              color="#FFFFFF"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="150%"
              sx={{
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}
            >
              INVITE FRIENDS
            </Text>
          </Button>
        </a>
        <Button
          w="100%"
          h="52px"
          borderRadius="71px"
          bg="#9763B4"
          mt="50px"
          onClick={() =>
            (window.location.href = '/login')
          }
        >
          <Text
            color="#FFFFFF"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="150%"
            sx={{
              fontFeatureSettings: "'clig' off, 'liga' off",
            }}
          >
            VIEW YOUR PASSES
          </Text>
        </Button>
      </VStack>
    </Box>
  );
}
